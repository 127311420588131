import { WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import SC1MForeground from './assets/images/promo/SC1M/foreground.png'
import PROMO12MBackground from './assets/images/promo/SC12M/background.png'
import PROMO12MForeground from './assets/images/promo/SC12M/foreground.png'
import SC12MELECForeground from './assets/images/promo/SC12MELEC/foreground.png'
import wriskFlex from './assets/images/wrisk-car-hero--cropped.svg'
import wriskStay from './assets/images/wrisk-contents-hero--cropped.svg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

export const wriskTheme: WriskTheme = {
  // Common
  name: 'Default',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],

  radii: ['0rem', '4px', '6px', '0rem', '0rem'],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    body: `'Inter', sans-serif`,
    header: `'Inter', sans-serif`,
    navbar: `'Inter', sans-serif`,
    buttons: `'Inter', sans-serif`,
    input: `'Inter', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  typoSizes: {
    xxl: {
      fontSize: ['28px', '32px', '40px', '48px'],
      lineHeight: ['36px', '40px', '48px', '56px'],
    },
    xl: {
      fontSize: ['24px', '24px', '28px', '32px'],
      lineHeight: ['32px', '32px', '36px', '40px'],
    },
    lg: {
      fontSize: ['20px', '20px', '24px'],
      lineHeight: ['28px', '28px', '32px'],
    },
    md: {
      fontSize: ['18px', '18px', '20px'],
      lineHeight: ['24px', '24px', '28px'],
    },
    base: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    xs: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  shadows: {
    default: '0 8px 40px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.07)',
    hover: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    input: '0 0 0 2px #4F46E5',
    inset: 'inset -8px 0 0 0 #fff, inset 8px 0 0 0 #fff',
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: 'inset 0 0 0 1px #E5E7EB',
    radioStandalone: '0 8px 40px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.07)',
    radioHover: 'inset 0 0 0 1px #9ca3af',
    radioChecked: 'inset 0 0 0 2px #4F46E5',
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['72px'],
  },
  colors: {
    chalk: '#ffffff',
    background: '#F9FAFB',
    body: '#111827',
    bodySecondary: '#4B5563',
    hyperlink: '#111827',
    focus: '#4F46E5',
    hover: '#F6F7F8',

    primaryAccent: '#4F46E5',
    secondaryAccent: '#4F46E5',

    foregroundPromoBanner: '#fff',
    backgroundPromoBanner: '#1F2937',

    foregroundPromoActivated: '#fff',
    backgroundPromoActivated: '#16A34A',

    textOnNavigation: '#111827',
    surfaceNavigation: '#ffffff',

    textInfo: '#4F46E5',
    surfaceInfoSubdued: '#EEF2FF',
    surfaceInfo: '#4F46E5',

    textCritical: '#DC2626',
    textOnCritical: '#fff',
    surfaceCritical: '#DC2626',
    surfaceCriticalHovered: '#B91C1C',
    surfaceCriticalSubdued: '#FEF2F2',

    textWarning: '#111827',
    textOnWarning: '#111827',
    surfaceWarning: '#FACC15',
    surfaceWarningSubdued: '#FFFAE8',

    textHighlight: '#4B5563',
    textOnHighlight: '#4B5563',
    surfaceHighlight: '#F3F4F6',
    surfaceHighlightSubdued: '#F3F4F6',

    textSuccess: '#15803D',
    textOnSuccess: '#fff',
    surfaceSuccess: '#16A34A',
    surfaceSuccessSubdued: '#F0FDF4',

    border: '#EBEDF0',
    divider: '#EBEDF0',
    inactive: '#9CA3AF',
    placeholder: '#9CA3AF',

    textDisabled: '#4B5563',
    surfaceDisabled: '#E5E7EB',

    textFooter: '#111827',
    dividerFooter: '#EBEDF0',
    surfaceFooter: '#fff',

    radioCheckedBackground: '#EEF2FF',
    radioCheckedForeground: '#4F46E5',

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#9CA3AF',

    progressBackgroundColor: '#EBEDF0',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'wrisk-stay': wriskStay as string,
    'wrisk-flex': wriskFlex as string,
    'showcase-flex': wriskFlex as string,
    'showcase-driveaway': wriskFlex as string,
    'showcase-annual': wriskFlex as string,
  },
  promoImages: {
    SC12MELEC: {
      foreground: SC12MELECForeground as string,
    },
    SC1M: {
      foreground: SC1MForeground as string,
    },
    SC12M: {
      foreground: PROMO12MForeground as string,
      background: PROMO12MBackground as string,
    },
    SC24M: {
      foreground: PROMO12MForeground as string,
      background: PROMO12MBackground as string,
    },
  },
  // Components
  NavBar: {
    default: {
      boxShadow: 'default',
    },
  },
  Heading: {
    default: {},
    h1: {},
    h2: {},
    h3: {},
    h4: {
      fontWeight: 'normal',
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        backgroundColor: 'primaryAccent',
        color: 'chalk',
      },
      inverted: {
        backgroundColor: 'chalk',
      },
      critical: {
        backgroundColor: 'surfaceCritical',
        color: 'textOnCritical',
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        boxShadow: 'inset 0 0 0 2px #E5E7EB',
      },
      inverted: {},
    },
  },
}
